import { useRef } from 'react';
import { Heap, heap } from './heap';

type HeapProxy = Pick<Heap, 'track'>;

/**
 * Get a handle to a Heap Analytics tracking object, which allows for
 * queueing events to track before Heap is ready, as well as tracking
 * events immediately after Heap is loaded.
 *
 * @example
 * ```typescript
 * const heap = useHeap();
 *
 * // ...
 *
 * useEffect(() => {
 *     heap.track('component load', { foo: 'bar' });
 * }, []);
 * ```
 */
export function useHeap(): HeapProxy {
    const heapProxy = useRef<HeapProxy>({
        track(event, properties) {
            heap.track(event, properties);
        }
    });

    return heapProxy.current;
}