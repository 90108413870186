import { trackHeapEvent } from "./trackHeapEvent";

/** 
 * Heap analytics. setting up type assertions.
 * 
 * Taken from {@link https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions}
 */
export interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  addEventProperties: (properties: Record<string, unknown>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  config: any;
}

declare global {
	interface Window {
		heap?: Heap;
	}
}

/**
 * A proxy for the `window.heap` API, which allows for events
 * to be queued so they will be tracked once Heap is loaded.
 */
export const heap = {
  /**
   * If Heap is loaded, track a Heap event. Otherwise, add it
   * to a queue of events to be tracked once Heap is loaded.
   */
  track: trackHeapEvent,
};
