import { Heap } from './heap';

interface HeapEventData {
    event: Parameters<Heap["track"]>[0];
    properties: Parameters<Heap["track"]>[1];
}

let eventQueue: HeapEventData[] = [];

// Watch for when heap loads. When it's ready, use it and also track queued events.
let retry = 0;
let timer: ReturnType<typeof setInterval> | null = null;

/**
 * If Heap is loaded, track a Heap event. Otherwise, add it
 * to a queue of events to be tracked once Heap is loaded.
 */
export function trackHeapEvent(...[event, properties]: Parameters<Heap["track"]>): void {
    // console.log('trackHeapEvent', Array.from(arguments));
    if (window.heap) {
        window.heap.track(event, properties);
    } else {
        eventQueue.push({ event, properties });
        // The first time we try to track an event and Heap doesn't exist,
        // start looking for when Heap is loaded
        if (timer === null) {
            timer = setInterval(() => {
                if (window?.heap) {
                    trackEventsInQueue(window.heap);
                    // Timer never gets set back to null
                    clearInterval(timer!);
                    return;
                }
                retry++;
                // Timer never gets set back to null
                if (retry >= 5) { clearInterval(timer!); }
            }, 1000);
        }
    }
}

/**
 * Track all events in the event queue, then clear it.
 */
const trackEventsInQueue = (heap: Heap) => {
    for (const { event, properties } of eventQueue) {
        heap.track(event, properties);
    }

    // Clear queue after tracking everything
    eventQueue = [];
};
