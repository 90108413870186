import PropTypes from 'prop-types';
import { InferProps, Requireable, ValidationMap, Validator } from 'prop-types';

type AcfBlockPropsValidator<Content extends ValidationMap<unknown>, Config extends ValidationMap<unknown>> = {
	block: Requireable<InferProps<{
		content: Validator<NonNullable<InferProps<Content>>>,
		config: Validator<NonNullable<InferProps<{
			className: Validator<string>,
		} & Config>>>,

		id: Validator<string>,
		name: Validator<string>,
		type: Validator<string>,
	}>>,
	displayName: Validator<string>,
};

export type AcfBlockProps<
	Content extends Record<string, unknown>,
	Config extends Record<string, unknown> = Record<string, unknown>
> = {
	block?: {
		content: Content;
		config: Config & {
			className: string;
		}

		id: string;
		name: string;
		type: string;
	};
	displayName: string;
};

/**
 * Construct an object representing the standard props structure used by blocks created with the `BlockBuilder` class
 *
 * @param {ValidationMap<unknown>} content The "content" props
 * @param {ValidationMap<unknown>} [config] The "config" props, if any
 */
export default function getAcfBlockProps<Content extends ValidationMap<unknown>>(content: Content): AcfBlockPropsValidator<Content, Record<string, never>>;
export default function getAcfBlockProps<Content extends ValidationMap<unknown>, Config extends ValidationMap<unknown>>(content: Content, config: Config): AcfBlockPropsValidator<Content, Config>;
export default function getAcfBlockProps<Content extends ValidationMap<unknown>, Config extends ValidationMap<unknown>>(contentArg: Content, configArg?: Config): AcfBlockPropsValidator<Content, Config> {
	// Type assertion is safe because the `config` argument is required if the `Config` generic type is specified
	const configObj = configArg || {} as Config;

	const content = PropTypes.shape(contentArg).isRequired;
	const config = PropTypes.shape({
		className: PropTypes.string.isRequired,
		...configObj,
	}).isRequired;

	const acfBlockProps = {
		block: PropTypes.shape({
			content,
			config,

			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}),
		displayName: PropTypes.string.isRequired,
	};

	return acfBlockProps;
}
