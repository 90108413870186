import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';
import getAcfBlockProps from 'components/prop-types/acf-block';
import { useHeap } from 'components/utils/heap';

const propTypes = {
    ...getAcfBlockProps(
        {
            event_name: PropTypes.string.isRequired,
            test_version: PropTypes.string.isRequired,
            event_details: PropTypes.string.isRequired,
        }
    ),
};
type HeapCustomEventPropTypes = InferProps<typeof propTypes>;

const defaultProps = {};


// This block creates a custom Heap event that will be sent to Heap on page load. Note this only works for Next.js pages.
const HeapCustomEvent = Object.assign(
    function HeapCustomEvent(props: HeapCustomEventPropTypes) {
        const content = props.block?.content;

        const heap = useHeap();

        // useEffect to only run code once
        useEffect(() => {
            // Heap event data
            heap.track('Marketing - '+content?.event_name, {
                "Test Version": content?.test_version,
                "Event details": content?.event_details,
            });

            return () => {
            };
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);


        if (!content) {
            return null;
        }

        return (
            <></>
        );
    },

    {
        propTypes,
        defaultProps,
    },
);

export default HeapCustomEvent;